import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const initMediaScroll = async () => {
    return new Promise((resolve) => {
        let proxy = { inset: 0 },
            insetSetter = gsap.quickSetter('.media-animation', 'clipPath'),
            insetClamp = gsap.utils.clamp(0, 15)

        ScrollTrigger.create({
            onUpdate: (self) => {
                let inset = insetClamp(Math.abs(self.getVelocity()) / 1500)

                if (Math.abs(inset) > Math.abs(proxy.inset)) {
                    proxy.inset = inset
                    gsap.to(proxy, {
                        inset: 0,
                        duration: 0.8,
                        ease: 'power3',
                        overwrite: true,
                        onUpdate: () => {
                            insetSetter(
                                `inset(${proxy.inset}% round calc(max(40 * var(--unit-fx), 40px)))`
                            )
                        }
                    })
                }
            }
        })

        gsap.set('.media-animation', {
            clipPath: `inset(0% round calc(max(40 * var(--unit-fx), 40px)))`, // Start with no inset, but with border-radius
            force3D: false
        })

        return resolve('Counter')
    })
}

export { initMediaScroll }
