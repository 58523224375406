import '@/css/app.css'
import Alpine from 'alpinejs'
import Headroom from 'headroom.js'
import collapse from '@alpinejs/collapse'
import resize from '@alpinejs/resize'
import customSelect from 'custom-select'

// import barba from '@barba/core'
import { initMediaScroll } from './base/media-scroll'

// App main
const main = async () => {
    headroom()
    setCustomSelect()

    const blocks = ['blockStickyList']

    const components = [
        'popup-nav',
        'counter',
        'maps',
        'parallax',
        'home-banner',
        'fancybox',
        'swiper',
        'accordion',
        'lang-switcher',
        'block-up',
        'block-sticky'
    ]

    const importPromises = []

    for (const block of blocks) {
        const blockElements = document.querySelectorAll(`.${block}`)
        if (blockElements.length === 0) {
            continue
        }

        const importPromise = import(`./blocks/${block}.js`).then(
            ({ init }) => {
                blockElements.forEach((block) => {
                    importPromises.push(init(block))
                })
            }
        )

        importPromises.push(importPromise)
    }

    for (const comp of components) {
        const compElements = document.querySelectorAll(`.${comp}`)
        if (compElements.length === 0) {
            continue
        }

        const importPromise = import(`./components/${comp}.js`).then(
            ({ init }) => {
                compElements.forEach((comp) => {
                    importPromises.push(init(comp))
                })
            }
        )

        importPromises.push(importPromise)
    }

    importPromises.push(
        import('./base/animations.js').then(({ initAnimations }) =>
            initAnimations()
        ),
        import('./base/smooth-scroll.js').then(({ initSmoothScroll }) =>
            initSmoothScroll()
        ),
        import('./base/media-scroll.js').then(({ initMediaScroll }) =>
            initMediaScroll()
        ),
        import('./base/scroll-to.js').then(({ initScrollTo }) => initScrollTo())
    )
    return await Promise.all(importPromises)
}

const headroom = () => {
    new Headroom(document.querySelector('header'), {
        offset: 50,
        onTop: function () {
            document.querySelector('body').classList.add('headroom--top')
            document.querySelector('body').classList.remove('headroom--not-top')
        },

        onNotTop: function () {
            document.querySelector('body').classList.remove('headroom--top')
            document.querySelector('body').classList.add('headroom--not-top')
        }
    }).init()
}

const setCustomSelect = () => {
    customSelect('select')

    if (typeof htmx !== 'undefined') {
        htmx.on('htmx:afterRequest', function (event) {
            customSelect('select')
        })
    }
}

main().then(() => {
    console.info('Javascript Functionalities loaded')
    window.Alpine = Alpine

    Alpine.plugin(collapse)
    Alpine.plugin(resize)
    Alpine.start()
})

// if (import.meta.hot) {
//     import.meta.hot.accept(() => {
//         console.log('HMR')
//     })
// }
